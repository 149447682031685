"use client"

import { useUbStorefrontState } from "@/store/ubStorefrontState"

export default function Loading() {
  const { isLoading } = useUbStorefrontState()

  return (
    <div className="pointer-events-none fixed z-[9999] size-full">
      {isLoading && (
        <>
          <div className="pointer-events-auto fixed size-full bg-black opacity-50"></div>
          <div className="pointer-events-auto grid size-full place-items-center">
            <div className="loading absolute opacity-100"></div>
          </div>
        </>
      )}
    </div>
  )
}
