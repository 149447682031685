"use client"

import React from "react"
import { useRouter, useSearchParams } from "next/navigation"
import { DEFAULT_SEARCH_RESULT_SORT_ORDER, SEARCH_PARAMS_SORT_ORDER } from "@/constants/ui"
import { useTranslations } from "next-intl"

import { Select, SelectContent, SelectItem, SelectTrigger } from "@/components/ui/components"
import { getPaginationSearchParams } from "@/components/search-and-category/pagination-info"

const SearchResultSortOrder = () => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const paginationSerachParams = getPaginationSearchParams(searchParams)
  const t = useTranslations("storefront")

  const handleChange = (value: string) => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(SEARCH_PARAMS_SORT_ORDER, value)
    router.push("?" + newSearchParams.toString())
  }

  return (
    <div>
      <Select
        aria-label={t("sort_order.sort_order")}
        value={paginationSerachParams.sortOrder}
        onValueChange={(value: string) => handleChange(value)}
      >
        <SelectTrigger className="border-none pr-0 pt-0 text-base">
          <span className="mr-2">{t("sort_order.sort_order")}</span>
          <span className="font-medium text-primary">{t("sort_order." + paginationSerachParams.sortOrder)}</span>
        </SelectTrigger>
        <SelectContent className="">
          {DEFAULT_SEARCH_RESULT_SORT_ORDER.map((key) => (
            <SelectItem className="cursor-pointer" value={key} key={key}>
              {t("sort_order." + key)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export default SearchResultSortOrder
