// To be used by both, server and by client components!

import { getSbLocaleFromHostAndUri } from "../locale-helper"
import { logger } from "../logger"
import { Locales } from "./constants.cjs"

export function getSbLocale(): string {
  const runsOnServerSide = typeof window === "undefined"
  if (runsOnServerSide) {
    const getSbLocaleOnServer = require("./get-sb-locale-server")
    return getSbLocaleOnServer()
  } else {
    const sbLocale = getSbLocaleFromHostAndUri(window.location.host, window.location.pathname)
    logger.debug(`getSbLocale() on client returns ${sbLocale}.`)
    return sbLocale
  }
}

// returns e.g. "de-AT", "nl-NL"
export function getIsoLocale() {
  return getIsoLocaleFromSbLocale(getSbLocale())
}

// return e.g. "de-at", "nl"
export function getShortLowerIsoLocale() {
  if (getCountry() == getLanguage()) {
    return getCountry()
  }
  return getIsoLocale().toLowerCase()
}

// returns e.g. "de_DE" (instead of de_AT - this is because of the intershop site config)
export function getIntershopLocale(): string {
  return getIntershopLocaleFromSbLocale(getSbLocale())
}

export function getIntershopLocaleFromSbLocale(sbLocale?: string): string {
  if (sbLocale === undefined) {
    sbLocale = getSbLocale()
  }
  return Locales.intershopLocalesForSbLocales[sbLocale]
}

// returns e.g. "de-AT"
export function getIsoLocaleFromSbLocale(sbLocale: string): string {
  return Locales.isoLocalesForSbLocales[sbLocale]
}

// returns e.g. "at" (lower case)
export function getCountry(): string {
  const isoLocale = getIsoLocale() // e.g. "de-AT"
  const country = isoLocale.split("-")[1] // will always have at least 2 parts
  return country.toLowerCase()
}

// returns e.g. "de" for "de-CH" (lower case)
export function getLanguage(): string {
  const isoLocale = getIsoLocale() // e.g. "de-AT"
  const language = isoLocale.split("-")[0] // will always have at least 2 parts
  return language.toLowerCase()
}

// returns "EUR" or "CHF" (in ch)
export function getCurrency(): string {
  return getCountry() === "ch" ? "CHF" : "EUR"
}
