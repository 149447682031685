"use client"

import { ChangeEvent, KeyboardEvent, useRef, useState } from "react"
import { debounce } from "next/dist/server/utils"
import { SearchIcon, XIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { fetchSuggestions } from "@/lib/factfinder/factfinder-backend"
import { SuggestionsResult } from "@/lib/factfinder/factfinder-interfaces"
import { getSearchLink } from "@/lib/link-utils/link-utils"
import { cn } from "@/lib/utils"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/components"

import StoryblokSuggestionResultHeader from "./StoryblokSuggestionResultHeader"
import StoryblokSuggestionResultItem from "./StoryblokSuggestionResultItem"

// see https://nextjs.org/learn/dashboard-app/adding-search-and-pagination
export default function StoryblokSearchBox({ onClose }: { onClose?: () => void }) {
  const t = useTranslations("storefront")
  const inputRef = useRef<HTMLInputElement>(null)
  const MIN_SEARCH_LENGTH = 2
  const [popUpOpen, setPopUpOpen] = useState(false)
  const [suggestions, setSuggestions] = useState<SuggestionsResult>({
    hasResults: false,
    query: "",
    products: [],
    categories: [],
    brands: [],
  })

  function submitIfSingleResult(suggestions: SuggestionsResult) {
    let searchQuery: string = suggestions.query

    if (searchQuery.trim() === "") {
      if (inputRef.current) {
        searchQuery = inputRef.current.value
      }
    }

    let link = getSearchLink(searchQuery)
    const totalResults = suggestions.products.length + suggestions.categories.length + suggestions.brands.length

    if (totalResults === 1) {
      if (suggestions.products.length === 1) {
        link = suggestions.products[0].link
      } else if (suggestions.categories.length === 1) {
        link = suggestions.categories[0].link
      } else if (suggestions.brands.length === 1) {
        link = suggestions.brands[0].link
      }
    }
    window.location.href = link
    //router.push(link)
  }

  async function handleSuggestion(query: string) {
    const suggestion = await fetchSuggestions(query)
    setSuggestions(suggestion)
    setPopUpOpen(true)
    return suggestion
  }

  const handleSearchChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= MIN_SEARCH_LENGTH) return
    handleSuggestion(event.target.value)
  }, 800)

  const handlePopupAutoFocus = (event: Event) => {
    event.preventDefault()
  }

  const keyDownHandler = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const suggestions = await handleSuggestion(event.currentTarget.value)
      submitIfSingleResult(suggestions)
    }
  }

  const buttonClickHandler = () => {
    submitIfSingleResult(suggestions)
  }

  return (
    <div className="relative mx-auto flex size-full text-gray-600">
      <input
        className={cn(
          "size-full rounded rounded-r-none border-y border-l border-gray-300 bg-white px-5 py-1.5 text-base outline-none hover:border-black focus:border-blue-900",
          {
            "pr-12": !!onClose,
          }
        )}
        autoFocus
        name="search"
        placeholder={t("header.search_placeholder")}
        onChange={handleSearchChange}
        onKeyDown={keyDownHandler}
        ref={inputRef}
      ></input>
      {!!onClose && (
        <button
          onClick={onClose}
          className="absolute inset-y-0 right-12 flex items-center px-2 text-gray-500 focus:outline-none"
        >
          <XIcon />
        </button>
      )}
      <button
        onClick={buttonClickHandler}
        aria-label={t("header.search_placeholder")}
        type="submit"
        className="w-12 rounded-r-lg bg-primary text-white"
      >
        <SearchIcon strokeWidth={2} className="mx-auto size-5" />
      </button>
      <div className="absolute flex w-full justify-center">
        <Popover open={popUpOpen && suggestions.hasResults} onOpenChange={setPopUpOpen}>
          <PopoverTrigger aria-label={t("header.search_placeholder")}></PopoverTrigger>
          <PopoverContent
            onOpenAutoFocus={handlePopupAutoFocus}
            onCloseAutoFocus={handlePopupAutoFocus}
            className="max-h-[930px] w-[900px] overflow-auto p-0"
            sideOffset={40}
          >
            <div className="flex flex-col gap-0.5">
              {suggestions.products.length > 0 && (
                <StoryblokSuggestionResultHeader
                  description={t("suggestion.result.header.products")}
                ></StoryblokSuggestionResultHeader>
              )}
              {suggestions.products.map((product, index) => (
                <StoryblokSuggestionResultItem key={index} query={suggestions.query} suggestion={product} />
              ))}
              {suggestions.categories.length > 0 && (
                <StoryblokSuggestionResultHeader
                  description={t("suggestion.result.header.categories")}
                ></StoryblokSuggestionResultHeader>
              )}
              {suggestions.categories.map((category, index) => (
                <StoryblokSuggestionResultItem key={index} query={suggestions.query} suggestion={category} />
              ))}
              {suggestions.brands.length > 0 && (
                <StoryblokSuggestionResultHeader
                  description={t("suggestion.result.header.brands")}
                ></StoryblokSuggestionResultHeader>
              )}
              {suggestions.brands.map((brand, index) => (
                <StoryblokSuggestionResultItem key={index} query={suggestions.query} suggestion={brand} />
              ))}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}
