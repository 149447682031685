"use client"

import React, { useCallback, useEffect, useRef, useState } from "react"
import { UB_OPTANON_ALERT_BOX_CLOSED_COOKIE_NAME } from "@/constants"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import Cookies from "js-cookie"
import { useTranslations } from "next-intl"

import { CustomerTypeCookieValue, UB_CUSTOMER_TYPE_COOKIE_NAME } from "@/lib/customer-type-cookies"
import { Button } from "@/components/ui/components"
import { setCustomerTypeCookie } from "@/app/actions"

export default function InitialCustomerTypeDialogClient() {
  const [visible, setVisible] = useState<boolean>(false)
  const { setIsB2BCustomer } = useUbStorefrontState()

  const intervalId = useRef<undefined | ReturnType<typeof setInterval>>(undefined)
  const t = useTranslations("storefront")

  const waitForOptanonAlertBoxClosed = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
    }
    intervalId.current = setInterval(() => {
      if (Cookies.get(UB_OPTANON_ALERT_BOX_CLOSED_COOKIE_NAME)) {
        const b2bCustomerCookieValue = Cookies.get(UB_CUSTOMER_TYPE_COOKIE_NAME)
        setVisible(!b2bCustomerCookieValue) // no dialog if cookie is set
        clearInterval(intervalId.current)
      }
    }, 500)
  }, [])

  function selectCustomerType(type: CustomerTypeCookieValue) {
    setCustomerTypeCookie(type).then(() => setIsB2BCustomer(CustomerTypeCookieValue.isB2bCustomer(type)))
    setVisible(false)
  }

  function selectB2B() {
    selectCustomerType(CustomerTypeCookieValue.B2B)
  }

  function selectB2C() {
    selectCustomerType(CustomerTypeCookieValue.B2C)
  }

  useEffect(() => {
    const cookieValue = Cookies.get(UB_CUSTOMER_TYPE_COOKIE_NAME)
    if (cookieValue !== CustomerTypeCookieValue.B2B && cookieValue !== CustomerTypeCookieValue.B2C) {
      waitForOptanonAlertBoxClosed()
    }
  }, [waitForOptanonAlertBoxClosed])

  return (
    <>
      {visible && (
        <div className={"fixed inset-0 z-50 bg-black/90"}>
          <div className={"absolute left-1/2 top-1/3 -translate-x-1/2 -translate-y-1/2 pt-4"}>
            <div className={"flex flex-col gap-10 bg-white p-6"}>
              <h2 className={"text-2xl font-bold"}>{t("customer.initial.header")}</h2>
              <p className={"text-xl"}>{t("customer.initial.description")}</p>
              <div className={"flex  justify-between gap-14"}>
                <Button onClick={selectB2B} className={"grow text-xl font-bold"}>
                  {t("common.yes")}
                </Button>
                <Button onClick={selectB2C} className={"grow text-xl font-bold"}>
                  {t("common.no")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
