// see https://github.com/vercel/next.js/issues/49757
// -> workaround below
// import "server-only"
// import { headers } from "next/headers"
import { NEXT_HEADER_SB_LOCALE, NEXT_HEADER_URL_KEY } from "@/constants"

import { logger } from "../logger"
import { Locales } from "./constants.cjs"

const getSbLocaleOnServer = function (): string {
  const headers = require("next/headers").headers
  const sbLocaleFromHeader = headers().get(NEXT_HEADER_SB_LOCALE)
  const urlFromHeader = headers().get(NEXT_HEADER_URL_KEY)

  let sbLocale: string
  // note: sbLocaleFromHeader may correctly be empty ("")
  if (sbLocaleFromHeader == null) {
    logger.error(
      `getSbLocaleOnServer(): Internal error: locale header ${NEXT_HEADER_SB_LOCALE} is not set during request ${urlFromHeader}.`
    )
    console.trace()
    sbLocale = Locales.defaultSbLocale
  } else {
    sbLocale = sbLocaleFromHeader
  }
  // logger.debug(`getSbLocaleOnServer returns ${sbLocale}.`)
  return sbLocale
}

module.exports = getSbLocaleOnServer
