import { SEARCH_PARAMS_FF_SEARCH_PARAMS } from "@/constants/ui"

import { logger } from "../logger"
import { Category } from "../storefront/category/category"
import { getSbLocale } from "./get-sb-locale"

export function getLanguageUriPrefix(): string {
  return getSbLocale() === "fr-ch" ? "/fr/CHF" : ""
}

export function baseUrl(trailingSlash = false): string {
  const baseUrl = process.env.NEXT_GENERATED_LINKS_BASE_URL ?? ""
  return baseUrl + getLanguageUriPrefix() + (trailingSlash ? "/" : "")
}

function getCategoryLinkByUrlSlug(urlSlug: string, encryptedFfSearchParams?: string): string {
  const nodeIsProductionMode = process.env.NODE_ENV === "production"
  const deployEnvIsEmptyOrDev = !process.env.DEPLOY_ENV || process.env.DEPLOY_ENV.startsWith("dev")
  const localDevelopment = !nodeIsProductionMode && deployEnvIsEmptyOrDev
  const catPrefix = localDevelopment ? "category/" : ""
  let link = `${baseUrl()}/${catPrefix}${urlSlug}/`
  if (encryptedFfSearchParams) {
    link += `?${SEARCH_PARAMS_FF_SEARCH_PARAMS}=${encryptedFfSearchParams}`
  }
  return link
}

export function getCategoryLinkByName(categoryName: string, encryptedFfSearchParams?: string): string {
  return getCategoryLinkByUrlSlug(slugifyUB(categoryName), encryptedFfSearchParams)
}

export function getSearchLink(query: string, encryptedFfSearchParams?: string): string {
  let link = `${baseUrl()}/search/${encodeURIComponent(query)}/`
  if (encryptedFfSearchParams) {
    link += `?${SEARCH_PARAMS_FF_SEARCH_PARAMS}=${encryptedFfSearchParams}`
  }
  return link
}

export function getLinkWithEncryptedFfSearchParams(
  query?: string,
  category?: Category,
  encryptedFfSearchParams?: string
): string {
  if (query) {
    return getSearchLink(query, encryptedFfSearchParams)
  } else if (category) {
    return getCategoryLinkByUrlSlug(category.urlSlug, encryptedFfSearchParams)
  } else {
    logger.warn("getLinkToSearchResultOrCategory: No query and no base category provided.")
    return ""
  }
}

export function getResetLinkToSearchResultOrCategory(query?: string, category?: Category): string {
  if (query) {
    return getSearchLink(query)
  } else if (category) {
    return getCategoryLinkByUrlSlug(category.urlSlug)
  } else {
    logger.warn("getLinkToSearchResultOrCategory: No query and no base category provided.")
    return ""
  }
}

export function getBrandLinkByName(brandname: string): string {
  return getBrandLinkByUrlSlug(slugifyUB(brandname))
}

export function getBrandLinkByUrlSlug(urlSlug: string): string {
  return `${baseUrl()}/brands/${urlSlug}`
}

//SEE: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
export function slugifyUB(input: string): string {
  if (input === null || input === undefined) {
    return ""
  }

  // lower case
  let result: string = input.toLowerCase()

  // now convert lowercase umlauts and szlig
  result = result
    .replace(/ä/g, "ae")
    .replace(/ö/g, "oe")
    .replace(/ü/g, "ue")
    .replace(/ß/g, "ss")
    // convert whitespaces and plus signs to minus
    .replace(/ /g, "-")
    .replace(/\+/g, "-")

  // strip accents
  result = result.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

  // replace everything that is not a letter or number
  result = result.replace(/[^a-zA-Z0-9\-]/g, "")

  // remove trailing dashes
  result = result.replace(/^\-+/, "")
  result = result.replace(/\-+$/, "")

  // replace multiple consecutive dashes with a single dash
  result = result.replace(/\-+/g, "-")

  return result
}

/* TODO - need to check if the above code is really correct
Legacy JAVA Code Found in UBRewriteHelper:

  public static String slugify(String input) {
    if (input == null) {
      return "";
    }
    // lower case
    String result = input.toLowerCase();

    // now convert lowercase umlauts and szlig
    result = result
        .replaceAll("\u00e4", "ae")
        .replaceAll("\u00f6", "oe")
        .replaceAll("\u00fc", "ue")
        .replaceAll("\u00df", "ss")
    // convert whitespaces and plus signs to minus
        .replaceAll(" ", "-")
        .replaceAll("\\+", "-");

    // replaces characters with accents used in French or Czech language
    result = StringUtils.stripAccents(result);

    // replace everything that is not a letter
    result = result.replaceAll("[^a-zA-Z0-9\\-]", "");

    // remove trailing dashes
    result = result.replaceAll("^[\\-]{1,}", "");
    result = result.replaceAll("[\\-]{1,}$", "");

    // replace multiple consecutive dashes with a single dash
    result = result.replaceAll("[\\-]{2,}", "-");
    return result;
  }

}
*/

export function appendQueryParameter(url: string, keyToAppend: string, valueToAppend: string): string {
  const dummyBase = "http://dummy"

  let urlObj
  if (url.startsWith("http://") || url.startsWith("https://")) {
    urlObj = new URL(url)
  } else {
    urlObj = new URL(url, dummyBase)
  }

  urlObj.searchParams.set(keyToAppend, valueToAppend)

  if (urlObj.origin === dummyBase) {
    return urlObj.pathname + urlObj.search
  }

  return urlObj.toString()
}
