// must be js because it's needed by next.config.js

// Our url scheme handling (around rewrites/redirects below)
// is not able to handle story slugs with directories ("/" in the name).
// So these are ignored in the URL
// and we have this workaround in storyblock-backend.ts:handleStoriesInDirectories:
// -> Everything starting with "abc_" gets prepended the directory "abc/". so We get "abc/abc_"
module.exports = {
  // prettier-ignore
  redirectsToInfo: [
    "service",
    "impressum", "mentions-legales", "colofon",
    "markenindex", "marques-index", "merk-index",
    "bildnachweise",
    "home", // only for dev convenience
  ],
  defaultUbLocaleByDomain: {
    "udobaer.at": "at-de",
    "udobaer.ch": "ch-de",
    "udobaer.de": "de-de",
    "udobaer.fr": "fr-fr",
    "udobaer.nl": "nl-nl",
    "": "de-de", // Default (when the others don't match)
  },
  specialDirectories: ["playground", "global", "landing", "lp", "acceptance"],
}
