import Link from "next/link"

import { FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { getLinkWithEncryptedFfSearchParams } from "@/lib/link-utils/link-utils"
import { Category } from "@/lib/storefront/category/category"

interface FacetElementProps {
  facetElementData: FacetElementData
  query?: string
  baseCategory?: Category
}

function FacetElement(props: FacetElementProps) {
  const hitcount = props.facetElementData.hitcount
  const elementname = props.facetElementData.elementname
  const selected = props.facetElementData.selected
  const link = getLinkWithEncryptedFfSearchParams(
    props.query,
    props.baseCategory,
    props.facetElementData.encryptedFfSearchParams
  )

  return (
    <Link href={link}>
      <div className="flex min-w-40 flex-row items-center gap-1">
        <input name="filter_{elementname}" type="checkbox" defaultChecked={selected} />
        {elementname}&nbsp;({hitcount})
      </div>
    </Link>
  )
}

export default FacetElement
