import Image from "next/image"
import { ProductPriceContainerCtnrROV1 } from "@ub/pricing-client"
import { useTranslations } from "next-intl"

import { ReducedProductData } from "@/lib/storefront/product/product"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/components"
import ProductCardAddToBasket from "@/components/product/ProductCardAddToBasket"
import ProductCardDiscount from "@/components/product/ProductCardDiscount"
import ProductCardPrice from "@/components/product/ProductCardPrice"

import { getResizedSbImagepath } from "../ui/helpers/resize-sb-image"

function determineDirectlyAddableArticleId(productData: ReducedProductData | undefined) {
  if (productData?.mastered) {
    return productData?.id ?? null
  } else {
    return null
  }
}

function determineAvailability(
  productData: ReducedProductData | undefined,
  standardVariant: ReducedProductData | undefined,
  t: any
): string {
  let min: number | undefined
  let max: number | undefined
  // if (standardVariant) {
  //   min = standardVariant.readyForShipmentMin
  //   max = standardVariant.readyForShipmentMax
  // } else {
  min = productData?.readyForShipmentMin
  max = productData?.readyForShipmentMax
  // }

  if (min && max) {
    if (min <= 2) {
      return t("product.shipment_within48")
    } else if (min <= 3) {
      return t("product.shipment_days", { shipment: "2-3" })
    } else if (min <= 5) {
      return t("product.shipment_days", { shipment: "3-5" })
    } else if (min <= 7) {
      return t("product.shipment_days", { shipment: "5-7" })
    } else if (min <= 10) {
      return t("product.shipment_days", { shipment: "7-10" })
    } else if (min <= 15) {
      return t("product.shipment_days", { shipment: "10-15" })
    } else if (min <= 21) {
      return t("product.shipment_weeks", { shipment: "4" })
    } else if (min <= 25) {
      return t("product.shipment_weeks", { shipment: "4-5" })
    } else {
      return t("product.shipment_weeks", { shipment: min / 7 + "-" + max / 7 })
    }
  } else {
    return "" // "Test: " + min + "-" + max
  }
}

export default function ProductCard(props: {
  productData: ReducedProductData | undefined
  standardVariant: ReducedProductData | undefined
  showDescription: boolean | undefined
  showEcoTaxFlag: boolean | undefined
  prices: ProductPriceContainerCtnrROV1 | undefined
  compact?: boolean
}) {
  const t = useTranslations("storefront")

  //If a product is mastered, then we assume that it could be sold directly otherwise the customer needs to choose a variant first on PDP
  const hasVariants = !props.productData?.mastered

  const hasMulticolor = props.productData?.hasMultiColor

  const directlyAddableArticleId = determineDirectlyAddableArticleId(props.productData)

  const availabilityTextBase = determineAvailability(props.productData, props.standardVariant, t)
  const availabilityText = availabilityTextBase ? "(" + availabilityTextBase + ")" : ""

  return (
    <>
      {props.productData ? (
        <a className="size-full @container/productcard" href={props.productData.link}>
          <ProductCardDiscount prices={props.prices} />
          {/*inner main div*/}
          <div
            className={cn("flex h-full flex-1 grow flex-col justify-between gap-2 p-4", {
              "p-2": props.compact,
            })}
          >
            {/*top half*/}
            <div className="flex flex-col items-center justify-center gap-2">
              {/*image*/}
              <div
                className={cn("flex max-h-52 items-center", {
                  "max-h-36": props.compact,
                })}
              >
                {props.productData.image ? (
                  <Image
                    unoptimized={true}
                    className={cn("aspect-square max-h-52 overflow-hidden object-contain", {
                      "max-h-36": props.compact,
                    })}
                    alt={props.productData.title}
                    src={getResizedSbImagepath(props.productData.image)}
                    width={props.productData.image_width}
                    height={props.productData.image_height}
                  />
                ) : (
                  ""
                )}
              </div>
              {/*title*/}
              <div className={"flex h-16 min-h-[5.625rem] w-full justify-start"}>
                <h1
                  className={cn("line-clamp-4 text-sm font-bold leading-tight text-gray-900 sm:text-base lg:text-lg", {
                    "text-base": props.compact,
                  })}
                >
                  {props.productData.title}
                </h1>
              </div>
            </div>
            {/*description*/}
            {props.showDescription && (
              <div className={"prose-sm flex min-h-16 w-full grow justify-start leading-tight"}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.productData.description ? props.productData.description : "",
                  }}
                />
              </div>
            )}
            {/*bottom half*/}
            <div className="flex min-h-40 flex-col justify-end gap-2 md:min-h-44 lg:min-h-48">
              {/*prices and buttons*/}
              <div className={"flex h-full flex-col justify-between gap-4"}>
                <ProductCardPrice
                  showEcoTaxFlag={props.showEcoTaxFlag}
                  productData={props.productData}
                  prices={props.prices}
                />
                {/*truck and multicolor*/}
                <div className={"flex w-full grow justify-between gap-12"}>
                  <div className="text-wrap-balance flex items-center justify-start gap-2">
                    <Image
                      unoptimized={true}
                      width={32}
                      height={32}
                      className={"aspect-square h-8 lg:h-6 xl:h-8"}
                      alt={""}
                      src={"/img/delivery.svg"}
                    />
                    <div className={"flex flex-col items-start justify-center"}>
                      <p className={"text-xs lg:text-[0.65em] xl:text-xs"}>{t("product.available")}</p>
                      <p className={"text-nowrap text-xs lg:text-[0.65em] xl:text-xs"}>{availabilityText}</p>
                    </div>
                  </div>
                  {hasMulticolor && (
                    <div className="flex items-center justify-end gap-2">
                      <Image
                        unoptimized={true}
                        width={32}
                        height={32}
                        className={"aspect-square h-8 lg:h-6 xl:h-8"}
                        alt={""}
                        src={"/img/multicolor.svg"}
                      />
                      {!props.compact && (
                        <p className={"text-xs lg:text-[0.65em] xl:text-xs"}>{t("product.multiColor")}</p>
                      )}
                    </div>
                  )}
                </div>
                {/*buttons*/}
                <div className="flex flex-row items-end justify-end">
                  {hasVariants ? (
                    <Button size={"productCard"} className="w-full" variant={"default"}>
                      {t("product.go_to_variants")}
                    </Button>
                  ) : (
                    <div className={"flex w-full justify-between gap-x-2"}>
                      <div
                        className={cn("w-full md:w-3/4", {
                          "md:w-full": !directlyAddableArticleId,
                        })}
                      >
                        <Button size={"productCard"} className="w-full" variant={"default"}>
                          {t("product.go_to_product")}
                        </Button>
                      </div>
                      <div
                        className={cn("block md:block", {
                          "md:hidden w-0 md:w-0": !directlyAddableArticleId,
                        })}
                      >
                        {directlyAddableArticleId && (
                          <ProductCardAddToBasket
                            articleIdToAdd={directlyAddableArticleId}
                            productData={props.productData}
                            prices={props.prices}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </a>
      ) : (
        <p>No Data</p>
      )}
    </>
  )
}
