"use client"

import React, { useState } from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { useTranslations } from "next-intl"

import { FacetData } from "@/lib/factfinder/factfinder-interfaces"
import { getResetLinkToSearchResultOrCategory } from "@/lib/link-utils/link-utils"
import { Category } from "@/lib/storefront/category/category"
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger, Button } from "@/components/ui/components"
import { cn } from "@/components/ui/helpers/utils"
import Facet from "@/components/search-and-category/Facet"

import { FacetForBrand } from "./FacetForBrand"
import { FacetForPrice } from "./FacetForPrice"

interface FacetListProps {
  facets: FacetData[]
  query?: string
  baseCategory?: Category
  categoryAsTree?: boolean
  isFiltered: boolean
}

const FACET_COUNT_TO_SHOW = 11
const FACET_COUNT_TO_SHOW_EXPANDED = 4

function FacetList(props: FacetListProps) {
  const t = useTranslations("storefront")

  // const hasSelectedFacets = props.facets?.some((facetData) => facetData.selectedFacetElements.length > 0)
  const linkToResetAllFilters = getResetLinkToSearchResultOrCategory(props.query, props.baseCategory)

  const openFacets: string[] = props.facets?.slice(0, FACET_COUNT_TO_SHOW_EXPANDED).map((facet) => facet.name) || []

  const hasAnyFacets = !!props.facets?.length
  const needResetFilterButton = !hasAnyFacets || props.isFiltered

  const [showAll, setShowAll] = useState(false)
  let facetCountToShow: number = FACET_COUNT_TO_SHOW
  const hiddenCount = (props.facets?.length || 0) - facetCountToShow
  if (hiddenCount <= 1) {
    facetCountToShow = Infinity
  }

  const { filterShown, setFilterShown } = useUbStorefrontState()

  function handleCloseFilter() {
    setFilterShown(false)
  }

  return (
    <div className={cn("fixed inset-0 hidden bg-white lg:static lg:block", { "block z-[100]": filterShown })}>
      <div className="relative flex flex-col gap-4">
        <Button
          className={cn("absolute right-0 top-0 m-2 hidden lg:hidden", { block: filterShown })}
          onClick={handleCloseFilter}
          size={"icon"}
        >
          X
        </Button>
        <div className="flex flex-col gap-4">
          {needResetFilterButton && !hasAnyFacets && (
            <a className="mb-4 block" href={linkToResetAllFilters}>
              {t("common.reset_filters")}
            </a>
          )}
          {hasAnyFacets && (
            <AccordionRoot className="min-w-64 max-w-80" type="multiple" defaultValue={openFacets}>
              {needResetFilterButton && (
                <a className="mb-4 block" href={linkToResetAllFilters}>
                  {t("common.reset_filters")}
                </a>
              )}

              <div>
                {props.facets &&
                  props.facets.map((facet: FacetData, index: number) => (
                    <div key={index} className={index < facetCountToShow || showAll ? "block" : "hidden"}>
                      <FacetListItem index={index} facet={facet} {...props} />
                    </div>
                  ))}
                {props.facets && props.facets.length > facetCountToShow && (
                  <button className="p-4 text-xs text-gray-500" onClick={() => setShowAll(!showAll)}>
                    {showAll ? t("common.show_less") : t("common.show_further_count", { count: hiddenCount })}
                  </button>
                )}
              </div>
            </AccordionRoot>
          )}
        </div>
      </div>
    </div>
  )
}

interface FacetListItemProps {
  index: number
  facet: FacetData
  query?: string
  baseCategory?: Category
}

function FacetListItem({ index, facet, ...props }: FacetListItemProps) {
  const isBrand = facet.fieldName === "brand"
  const isPrice = facet.fieldName === "price"

  return (
    <AccordionItem key={index} className="mb-4 rounded-sm border border-gray-200 p-4 font-bold" value={facet.name}>
      <AccordionTrigger className="h-8 text-start shadow-none">{facet.name}</AccordionTrigger>
      <AccordionContent className="text-sm font-normal">
        {isBrand && <FacetForBrand query={props.query} baseCategory={props.baseCategory} facetData={facet} />}
        {isPrice && <FacetForPrice query={props.query} baseCategory={props.baseCategory} facetData={facet} />}
        {!isBrand && !isPrice && <Facet query={props.query} baseCategory={props.baseCategory} facetData={facet} />}
      </AccordionContent>
    </AccordionItem>
  )
}

export default FacetList
