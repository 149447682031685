"use client"

import { useState } from "react"
import { useTranslations } from "next-intl"

import { FacetData, FacetElementData } from "@/lib/factfinder/factfinder-interfaces"
import { Category } from "@/lib/storefront/category/category"

import FacetElement from "./FacetElement"

export interface FacetProps {
  facetData: FacetData
  query?: string
  baseCategory?: Category
  filterFunction?: (facetElement: FacetElementData) => boolean
}

const UNSELECTED_ELEMENT_COUNT_TO_SHOW = 10

export default function Facet(props: FacetProps) {
  const t = useTranslations("storefront")

  const [showAll, setShowAll] = useState(false)
  let showUnselectedOnlyFirst: number = UNSELECTED_ELEMENT_COUNT_TO_SHOW
  if (props.filterFunction) {
    // Show all elements if user used a filter
    showUnselectedOnlyFirst = Infinity
  }
  const hiddenCount = (props.facetData?.facetElements?.length || 0) - showUnselectedOnlyFirst
  if (hiddenCount <= 1) {
    showUnselectedOnlyFirst = Infinity
  }

  return (
    <div className="mt-3 flex flex-col">
      {/* selected elemets are always shown */}
      <div className="flex flex-col">
        {props.facetData?.selectedFacetElements &&
          props.facetData.selectedFacetElements
            .filter((elemData: FacetElementData) => !props.filterFunction || props.filterFunction(elemData))
            .map((elemData: FacetElementData, index) => (
              <FacetElement
                key={index}
                query={props.query}
                baseCategory={props.baseCategory}
                facetElementData={elemData}
              />
            ))}
      </div>

      {/* unselected elements: only show first showUnselectedOnlyFirst */}
      <div className="flex flex-col">
        {props.facetData?.facetElements &&
          props.facetData.facetElements
            .filter((elemData: FacetElementData) => !props.filterFunction || props.filterFunction(elemData))
            .map((elemData: FacetElementData, index) => (
              <div key={index} className={index < showUnselectedOnlyFirst || showAll ? "block" : "hidden"}>
                <FacetElement
                  key={index}
                  query={props.query}
                  baseCategory={props.baseCategory}
                  facetElementData={elemData}
                />
              </div>
            ))}
        {props.facetData?.facetElements && props.facetData.facetElements.length > showUnselectedOnlyFirst && (
          <button className="p-4 text-xs text-gray-500" onClick={() => setShowAll(!showAll)}>
            {showAll ? t("common.show_less") : t("common.show_further_count", { count: hiddenCount })}
          </button>
        )}
      </div>
    </div>
  )
}
