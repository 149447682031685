"use client"

import { useLayoutEffect, useState } from "react"

import { Locales } from "@/lib/link-utils/constants.cjs"
import { getCountry, getIsoLocale, getLanguage } from "@/lib/link-utils/get-sb-locale"

function getTranslatedSlugForCurrentStory(sbLocale: string, translated_slugs: any[] | null | undefined): string {
  if (!translated_slugs) {
    // no translations for this story: Always switch to start page.
    return ""
  }
  const translated_slug = translated_slugs.find((t) => t.lang === sbLocale)
  const result = translated_slug?.path || ""
  // console.log(`getTranslatedSlugToCurrentStory result=${result}`)
  return result
}

function getSwitchedUri(translated_slugs: any[] | null | undefined): string {
  // URI is for example:
  // German:        /info/ch-de/impressum
  // French: /fr/CHF/info/ch-fr/mentions-legales
  let currentSlug: string
  let translatedSlug: string
  let removePrefix: string
  let newPrefix: string
  let replaceString: string
  let replaceBy: string

  if (getIsoLocale() === "fr-CH") {
    currentSlug = getTranslatedSlugForCurrentStory("fr-ch", translated_slugs)
    translatedSlug = getTranslatedSlugForCurrentStory(Locales.defaultSbLocale, translated_slugs)
    removePrefix = "/fr/CHF"
    newPrefix = ""
    replaceString = "/ch-fr/"
    replaceBy = "/ch-de/"
  } else {
    currentSlug = getTranslatedSlugForCurrentStory(Locales.defaultSbLocale, translated_slugs)
    translatedSlug = getTranslatedSlugForCurrentStory("fr-ch", translated_slugs)
    removePrefix = ""
    newPrefix = "/fr/CHF"
    replaceString = "/ch-de/"
    replaceBy = "/ch-fr/"
  }

  const newUri =
    newPrefix +
    window.location.pathname
      .replace(currentSlug, translatedSlug)
      .replace(removePrefix, "")
      .replace(replaceString, replaceBy) +
    window.location.search

  return newUri || "/"
}

interface StoryblokSwissLocaleSwitchProps {
  translated_slugs?: null | any[]
}

export default function StoryblokSwissLocaleSwitch({ translated_slugs }: StoryblokSwissLocaleSwitchProps) {
  const [switchLanguageLink, setSwitchLanguageLink] = useState<string>("")

  useLayoutEffect(() => {
    setSwitchLanguageLink(getSwitchedUri(translated_slugs))
  }, [])

  return (
    <>
      {getCountry() === "ch" && (
        <div className={"ml-8 flex justify-end"}>
          <a className={"flex items-center justify-between gap-1"} href={switchLanguageLink}>
            <div className="relative aspect-square h-5 rounded-sm bg-red-600">
              <span className="absolute inset-x-[40%] inset-y-[10%] bg-white"></span>
              <span className="absolute inset-x-[10%] inset-y-[40%] bg-white"></span>
            </div>
            <span>{getLanguage() === "de" ? "FR" : "DE"}</span>
          </a>
        </div>
      )}
    </>
  )
}
