// To be used by both, server and by client components!

import { Locales } from "./link-utils/constants.cjs"
import urlScheme from "./link-utils/url-scheme.js"
import { logger } from "./logger"

export function getUbLocaleFromHostName(requestedHostName: string): string {
  for (const domain in urlScheme.defaultUbLocaleByDomain) {
    if (removePort(requestedHostName).endsWith(domain)) {
      const ubLocale: string = urlScheme.defaultUbLocaleByDomain[domain]
      return ubLocale
    }
  }
  return Locales.defaultUbLocaleForDefaultSbLocale
}

function removePort(hostname: string): string {
  return hostname.split(":")[0]
}

function getSbLocaleFromParams(ubLocaleFromUri: string, ubLocaleFromHostName: string, sbLocaleFromUri: string): string {
  // precedence:
  // lang from SB in URI
  // > language from URI in CH (see next.config)
  // > Hostname
  // > (ignored: locale in (original) URI)

  // ubLocaleFromUri must be checked before sbLocaleFromUri because of the risk of confusion:
  //     "/fr/chf" also matches sbLocale (-> "fr"), but desired result is "fr-ch" in this case.
  if (ubLocaleFromHostName === "ch-de" && ubLocaleFromUri) {
    return getSbLocaleFromUbLocale(ubLocaleFromUri)
  }

  if (sbLocaleFromUri) {
    return sbLocaleFromUri
  }

  if (ubLocaleFromHostName == Locales.defaultUbLocaleForDefaultSbLocale) {
    return Locales.defaultSbLocale
  }
  return getSbLocaleFromUbLocale(ubLocaleFromHostName)
}

export function getSbLocaleFromUbLocale(ubLocale: string): string {
  const locale = getSbLocaleFromUbLocaleWithoutFallback(ubLocale)
  return locale == Locales.shortLocaleForDefaultSbLocale ? Locales.defaultSbLocale : locale
}

function getSbLocaleFromUbLocaleWithoutFallback(ubLocale: string): string {
  if (!ubLocale) {
    return ubLocale
  }
  const parts = ubLocale.toLowerCase().split("-")
  if (parts.length <= 1) {
    return ubLocale
  }

  // "xy-xy" -> "xy"
  if (parts[0] == parts[1]) {
    return parts[0]
  }

  return parts[1] + "-" + parts[0]
}

export function getSbLocaleFromUri(uri: string): string {
  // match URI in the Storyblok generated form /de/...
  const sbLocaleRegex = new RegExp(
    `^\/(${Object.keys(Locales.isoLocalesForSbLocales)
      .filter((key) => key)
      .join("|")})([^a-z]|$)`
  )
  const match = uri.match(sbLocaleRegex)
  if (!match) {
    return ""
  }
  const sbLocaleFromUri = match[1]
  logger.debug(`getSbLocaleFromUri(${uri}) returns ${sbLocaleFromUri}.`)
  return sbLocaleFromUri
}

export function getUbLocaleFromUri(uri) {
  // const ubLocaleRegex: RegExp = /^\/([a-z]{2}(\/[a-z]{2,3})?)([^a-z]|$)/i // e.g. /fr/CHF
  // match URI in Udobär form where only /fr/CHF is relevant and has to be considered
  if (uri.toLowerCase().startsWith("/fr/chf/") || uri.toLowerCase() === "/fr/chf") {
    return "ch-fr"
  }
  return ""
}

export function getSbLocaleFromHostAndUri(hostname: string, uri: string) {
  const sbLocaleFromUri = getSbLocaleFromUri(uri)
  const ubLocaleFromUri = getUbLocaleFromUri(uri)

  const ubLocaleFromHostName = getUbLocaleFromHostName(hostname)
  const sbLocale = getSbLocaleFromParams(ubLocaleFromUri, ubLocaleFromHostName, sbLocaleFromUri)
  logger.debug(
    `getSbLocaleFromParams: hostname=${hostname}, uri=${uri} ubLocaleFromUri=${ubLocaleFromUri}, ubLocaleFromHostName=${ubLocaleFromHostName}, sbLocaleFromUri=${sbLocaleFromUri} -> sbLocale=${sbLocale}.`
  )
  return sbLocale
}
