"use client"

import { useSearchParams } from "next/navigation"
import { SEARCH_PARAMS_MAX_PRICE, SEARCH_PARAMS_MIN_PRICE } from "@/constants/ui"
import { useTranslations } from "next-intl"

import { getCurrency } from "@/lib/link-utils/get-sb-locale"

import FormWithSearchParams from "../ui/helpers/FormWithSearchParams"
import Facet, { FacetProps } from "./Facet"

export function FacetForPrice(props: FacetProps) {
  const t = useTranslations("storefront")

  const searchParams = useSearchParams()

  const minPrice = searchParams?.get(SEARCH_PARAMS_MIN_PRICE) || ""
  const maxPrice = searchParams?.get(SEARCH_PARAMS_MAX_PRICE) || ""

  const showSearchFields = true

  return (
    <div>
      <Facet query={props.query} baseCategory={props.baseCategory} facetData={props.facetData} />

      {showSearchFields && (
        <FormWithSearchParams
          method="GET"
          noValidate={true}
          name={"PriceFilterForm"}
          ownParams={[SEARCH_PARAMS_MIN_PRICE, SEARCH_PARAMS_MAX_PRICE]}
          className="mt-4 flex flex-row items-center gap-1"
        >
          <div className="flex w-full flex-row items-center rounded border border-gray-300 px-1 py-2 text-xs">
            <span className="px-1 text-black">{getCurrency()}</span>
            <input
              name={SEARCH_PARAMS_MIN_PRICE}
              // type="number" // also adds up/down buttons :-(
              defaultValue={minPrice}
              placeholder={t("common.min_price")}
              className="w-full border-transparent p-0 text-xs text-black ring-0 focus:border-transparent focus:outline-none focus:ring-0"
              // inputMode="numeric"
              maxLength={5}
              pattern="[0-9]{0,5}"
            />
          </div>

          <div className="flex w-full flex-row items-center rounded border border-gray-300 px-1 py-2 text-xs">
            <span className="px-1 text-black">{getCurrency()}</span>
            <input
              name={SEARCH_PARAMS_MAX_PRICE}
              // type="number" // also adds up/down buttons :-(
              defaultValue={maxPrice}
              placeholder={t("common.max_price")}
              className="w-full border-transparent p-0 text-xs text-black ring-0 focus:border-transparent focus:outline-none focus:ring-0"
              // inputMode="numeric"
              maxLength={5}
              pattern="[0-9]{0,5}"
            />
          </div>

          <button
            type="submit"
            className="rounded bg-blue-500 px-4 py-2 text-xs text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {t("common.go")}
          </button>
        </FormWithSearchParams>
      )}
    </div>
  )
}
